import { useState } from 'react'

import styles from './Agenda.module.scss'

import UserIcon from 'assets/images/user.png'
import classNames from 'classnames'
import { useResponsive } from 'hooks'
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg'

const Card = ({ data, isTrackList }: any) => {
  const [isOpen, setIsOpen] = useState(false)
  const { isMobileM } = useResponsive()

  const { date, title, language, hall, info, speackersList } = data

  return (
    <div
      onClick={() => isMobileM && setIsOpen(!isOpen)}
      className={classNames(styles.card, {
        [styles.card__active]: isOpen,
        [styles.card__track]: isTrackList,
        [styles.card__track_active]: isTrackList && isOpen,
      })}
    >
      <div className={styles.card__content}>
        <div className={styles.card__date}>
          <p>{date}</p>
        </div>
        <div className={styles.card__item}>
          <p className={styles.card__title}>
            {language && <span>{language}</span>} {title}
          </p>
          <p>{hall}</p>
        </div>

        <div className={styles.card__speacker}>
          {speackersList &&
            speackersList.map((speacker: any) => (
              <div
                key={speacker.id}
                className={classNames(styles.card__speacker__item, {
                  [styles.card__speacker__item_top]: speacker.name === 'Viktor Vedmich',
                })}
              >
                <img src={speacker.image ?? UserIcon} alt={speacker.name} />
                <div>
                  <p>{speacker.name}</p>
                  <span>{speacker.position}</span>
                </div>
              </div>
            ))}
        </div>
        <div className={styles.card__item}>
          {info || (isMobileM && speackersList?.length) ? (
            <div
              style={{ transform: `rotate(${isOpen ? 180 : 0}deg)` }}
              role='button'
              onClick={() => setIsOpen(!isOpen)}
              className={styles.card__item_arrow}
            >
              <ArrowIcon />{' '}
            </div>
          ) : (
            <div style={{ width: 24 }} />
          )}
        </div>
      </div>

      {isOpen && speackersList?.length && (
        <div className={styles.card__info}>
          <div />
          <div className={styles.card__info_content}>
            <div className={styles.card__speacker__mobile}>
              {isMobileM &&
                speackersList &&
                speackersList.map((speacker: any) => (
                  <div
                    key={speacker.id}
                    className={classNames(styles.card__speacker__item, {
                      [styles.card__speacker__item_top]: speacker.name === 'Viktor Vedmich',
                    })}
                  >
                    <img src={speacker.image ?? UserIcon} alt={speacker.name} />
                    <div>
                      <p>{speacker.name}</p>
                      <span>{speacker.position}</span>
                    </div>
                  </div>
                ))}
            </div>
            {info && <p className={styles.card__info_title}>ABOUT TALK:</p>}
            <p>{info}</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default Card

import type { FC } from 'react'

import Logo from 'assets/images/logo.png'
import videoFile from 'assets/videos/banner.mp4'

import styles from './HeaderBanner.module.scss'

const HeaderBanner: FC = () => (
  <section id='BECOME-A-SPEAKER' className={styles.banner}>
    <div className={styles.banner__content}>
      <div className={styles.banner__content__left}>
        <img src={Logo} className={styles.banner__content__left_logo} />

        <h1 className={styles.banner__content__left_title}>May 24, 2025</h1>
        <p>Yerevan Armenia </p>
        <a
          target='_blanck'
          href='https://www.eventbrite.com/e/aws-community-day-armenia-tickets-1208415977719?aff=oddtdtcreator'
          className={styles.banner__content__left_register}
        >
          Get Your Ticket
        </a>
      </div>
    </div>

    <div className={styles.banner__content__right}>
      <div className={styles.banner__content__right__bg} />
      <video
        loop
        muted
        autoPlay
        playsInline
        className='video-player'
        preload='metadata'
        controlsList='nodownload'
        onError={() => console.error('Error loading video')}
      >
        <source src={videoFile} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
    </div>
  </section>
)

export default HeaderBanner

import { useState, FC } from 'react'
import classNames from 'classnames'

import styles from './Venue.module.scss'

interface VenueProps {
  isEdition?: boolean
}

const Venue: FC<VenueProps> = ({ isEdition }) => {
  const [activePart, setActivePart] = useState<number>(0)

  const activeHandler = (index: number) => {
    setActivePart(index)
  }

  return (
    <div className={styles.three}>
      <div className={'container'}>
        <div className={styles.three__content}>
          <div className={styles.three__content_left}>
            <div className={styles.three__content_left__map}>
              <div style={{ height: '100%' }}>
                <iframe
                  src='https://yandex.com/map-widget/v1/?from=mapframe&ll=44.504461%2C40.193255&mode=search&oid=23749075970&ol=biz&z=16.9'
                  width='100%'
                  height='100%'
                />
              </div>
            </div>
          </div>

          <div className={styles.three__content_right}>
            <div
              role='button'
              onClick={() => activeHandler(0)}
              className={classNames(styles.three__content_right__item, {
                [styles.three__content_right__item_active]: activePart === 0,
              })}
            >
              <div className={styles.three__content_right__item_left}>
                <p className={styles.three__content_right__item_left_name}>Venue</p>
                <p className={styles.three__content_right__item_left_date}>
                  {isEdition ? 'Sep 14, 2024' : 'May 24, 2025'}
                </p>
              </div>

              <div className={styles.three__content_right__item_right}>
                <span className={styles.three__content_right__item_right_title}>American University of Armenia</span>
                <span>40 Marshal Baghramyan Ave, </span>
                <span>Yerevan 0019</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Venue

import AuaIcon from 'assets/images/spons/aua.png'
import AdobeIcon from 'assets/images/spons/adobe.png'
import DasMetaIcon from 'assets/images/spons/dasmeta.png'
import GridIcon from 'assets/images/spons/grid.png'
import JetBrainsIcon from 'assets/images/spons/jetBrains.png'
import AwsIcon from 'assets/images/spons/aws.svg'
import StaffIcon from 'assets/images/spons/staff.png'
import How2bIcon from 'assets/images/spons/how2b.png'
import LimushIcon from 'assets/images/spons/limush.png'
import AmeriaIcon from 'assets/images/spons/ameria.png'
import DataartIcon from 'assets/images/spons/dataart.png'
import RearmeniaIcon from 'assets/images/spons/rearmenia.jpg'
import BrainscaleIcon from 'assets/images/spons/brainscale.png'
import PowerdmarcIcon from 'assets/images/spons/powerdmarc.png'
import SuperannotateIcon from 'assets/images/spons/superannotate.png'

import { SponsorCardInfo } from 'components/Sponsors/SponsorCard'

const sponsorsList2024: SponsorCardInfo[] = [
  {
    id: 1,
    categoryName: 'PLATINUM',
    sponsors: [
      {
        id: 11,
        url: 'https://www.brainscale.cloud/',
        image: BrainscaleIcon,
        vacantionUrl: 'https://www.linkedin.com/jobs/view/4011959227',
      },
      {
        id: 12,
        url: 'https://powerdmarc.com/',
        image: PowerdmarcIcon,
        vacantionUrl:
          'https://docs.google.com/document/d/10Y-lK5DBmfuxZNKClQC-qzpKaq5wyJOX/edit?usp=sharing&ouid=114419069721083880783&rtpof=true&sd=true',
      },
    ],
  },
  {
    id: 2,
    categoryName: 'GOLD',
    sponsors: [
      {
        id: 22,
        url: 'https://ameriabank.am/',
        image: AmeriaIcon,
        vacantionUrl: 'https://ats.hirebee.ai/en/company/jobs/ameriabank-cjsc',
      },
    ],
  },
  {
    id: 3,
    categoryName: 'SILVER',
    sponsors: [
      {
        id: 33,
        url: 'https://www.dataart.com/',
        image: DataartIcon,
        vacantionUrl: 'https://www.dataart.team/vacancies?countries=4610',
      },
      {
        id: 34,
        url: 'https://www.superannotate.com/',
        image: SuperannotateIcon,
        vacantionUrl: 'https://superannotate.factorialhr.com/job_posting/senior-site-reliability-engineer-215933',
      },
    ],
  },
  {
    id: 4,
    categoryName: 'SPECIAL',
    sponsors: [
      {
        id: 45,
        url: 'https://cse.aua.am/',
        image: AuaIcon,
        vacantionUrl: 'https://drive.google.com/file/d/1fS1dSQmD_WUXN0sBCcwcag-7zbq4Jlmn/view?usp=sharing',
      },
      {
        id: 46,
        url: 'https://aws.amazon.com/',
        image: AwsIcon,
        vacantionUrl: null,
      },
    ],
  },
  {
    id: 5,
    categoryName: 'PARTNERS',
    sponsors: [
      {
        id: 55,
        url: 'https://www.facebook.com/limushprinting/',
        image: LimushIcon,
        vacantionUrl: null,
      },
      {
        id: 56,
        url: 'https://rearmenia.com/en/',
        image: RearmeniaIcon,
        vacantionUrl: null,
      },
      {
        id: 57,
        url: 'https://staff.am/',
        image: StaffIcon,
        vacantionUrl: null,
      },
      {
        id: 58,
        url: 'https://how2b.am/',
        image: How2bIcon,
        vacantionUrl: null,
      },
    ],
  },
]

const sponsorsList2025: SponsorCardInfo[] = [
  {
    id: 1,
    categoryName: 'PLATINUM',
    sponsors: [
      {
        id: 21,
        url: 'https://www.jetbrains.com/',
        image: JetBrainsIcon,
        vacantionUrl: null,
      },
      {
        id: 27,
        url: 'https://www.dasmeta.com/',
        image: DasMetaIcon,
        vacantionUrl: null,
      },
      {
        id: 23,
        url: 'https://www.griddynamics.com/',
        image: GridIcon,
        vacantionUrl: null,
      },
    ],
  },
  {
    id: 2,
    categoryName: 'GOLD',
    sponsors: [
      {
        id: 24,
        url: 'https://www.adobe.com/',
        image: AdobeIcon,
        vacantionUrl: null,
      },
    ],
  },
  {
    id: 4,
    categoryName: 'SPECIAL',
    sponsors: [
      {
        id: 45,
        url: 'https://cse.aua.am/',
        image: AuaIcon,
        vacantionUrl: null,
      },
      {
        id: 46,
        url: 'https://aws.amazon.com/',
        image: AwsIcon,
        vacantionUrl: null,
      },
    ],
  },
]

const sponsorsList = {
  2024: sponsorsList2024,
  2025: sponsorsList2025,
}

export default sponsorsList

import { FC, Suspense, useEffect, useMemo } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import { Helmet } from 'components'

import routesList from './routes'

const ScrollToTop = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [location])

  return null
}

export const RoutesWrapper: FC = () => {
  const renderRoutes = useMemo(
    () =>
      routesList.map(({ element: Element, path, title }) => (
        <Route
          key={path}
          path={path}
          element={
            <Suspense>
              <Helmet key={title} title={title}>
                <Element />
              </Helmet>
            </Suspense>
          }
        />
      )),

    []
  )

  return (
    <>
      <Routes>{renderRoutes}</Routes>
      <ScrollToTop />
    </>
  )
}

import { teamData } from 'utils'

import styles from './Team.module.scss'
import TeamCard from './TeamCard'
import { Swiper } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import { FC, useRef } from 'react'
import { SwiperSlide } from 'swiper/react'
import { useResponsive } from 'hooks'
import classNames from 'classnames'

interface TeamProps {
  isEdition?: boolean
}

const Team: FC<TeamProps> = ({ isEdition = false }) => {
  const swiperRef = useRef()
  const { isTabletLarge, isMobileM } = useResponsive()

  const activeVersionData = teamData[isEdition ? 0 : 1]
  const renderTeamList = activeVersionData.map(data => <TeamCard key={data.id} data={data} />)

  const renderSliderTeamList = activeVersionData.map(data => (
    <SwiperSlide key={data.id}>
      <TeamCard key={data.id} data={data} />
    </SwiperSlide>
  ))

  const pagination = {
    clickable: true,
    renderBullet: function (_: number, className: string) {
      return `<span class="${className}"> </span>`
    },
  }

  return (
    <section className={styles.container} id='TEAM'>
      <div className='container'>
        <h2 className={styles.title}>team</h2>
        {!isTabletLarge ? (
          <div className={styles.wrapper}>{renderTeamList}</div>
        ) : (
          <div className={classNames(styles.feedback, 'teamSlider')}>
            <Swiper
              loop
              initialSlide={1}
              onBeforeInit={(swiper: any) => {
                swiperRef.current = swiper as any
              }}
              pagination={pagination}
              modules={[Autoplay, Pagination, Navigation]}
              spaceBetween={28}
              slidesPerView={isMobileM ? 1 : 2}
            >
              <>{renderSliderTeamList}</>
            </Swiper>
          </div>
        )}
      </div>
    </section>
  )
}

export default Team

import classNames from 'classnames'
import { useState, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperType } from 'swiper'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'

import styles from './Review.module.scss'

import { feedbackList, statisticsList } from 'utils'
import { FeedbackCard, StatisticsCard } from 'components'
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg'

const youtubeId = 'https://www.youtube.com/embed/dW2fbKD1x_A?si=qHXVf2CFW7cPfA3i'

interface ReviewProps {
  isEdition?: boolean
}

const Review = ({ isEdition = false }: ReviewProps) => {
  const swiperRef = useRef()
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const renderStatisticsList = statisticsList.map(statistics => (
    <StatisticsCard key={statistics.id} data={statistics} />
  ))

  const renderFeedbackList = feedbackList.map(feedback => (
    <SwiperSlide key={feedback.id}>
      <FeedbackCard data={feedback} />
    </SwiperSlide>
  ))

  const updateIndex = (swiperInstance: SwiperType) => {
    if (swiperInstance === null) return
    const currentSlide = swiperInstance?.activeIndex
    setCurrentIndex(currentSlide)
  }

  const pagination = {
    clickable: true,
    renderBullet: function (_: number, className: string) {
      return `<span class="${className}"> </span>`
    },
  }

  return (
    <section className={styles.wrapper}>
      <div className='container'>
        {isEdition ? <h2 className={styles.title}>AWS Community Day Armenia 2024</h2> : null}

        {isEdition ? <div className={styles.statistics}>{renderStatisticsList}</div> : null}

        <div className={classNames(styles.feedback, 'statisticSlider')}>
          <h2 className={styles.title}>What You Shared</h2>
          <Swiper
            loop
            initialSlide={1}
            onRealIndexChange={updateIndex}
            onBeforeInit={(swiper: any) => {
              swiperRef.current = swiper as any
            }}
            pagination={pagination}
            modules={[Autoplay, Pagination, Navigation]}
            spaceBetween={28}
            slidesPerView={1}
          >
            {renderFeedbackList}

            <button
              disabled={!currentIndex}
              className='swaper--prev'
              onClick={() => {
                if (currentIndex < 3) {
                  ;(swiperRef.current as any)?.slideTo(0)
                } else {
                  ;(swiperRef.current as any)?.slideTo(currentIndex - 1)
                }
              }}
            >
              <ArrowIcon />
            </button>
            <button
              disabled={currentIndex === feedbackList.length - 1}
              className='swaper--next'
              onClick={() => (swiperRef.current as any)?.slideTo(currentIndex + 1)}
            >
              <ArrowIcon />
            </button>
          </Swiper>
        </div>

        {!isEdition ? null : (
          <div className={styles.video}>
            <h2 className={styles.video__title}>Our Official Recap Film</h2>
            <iframe
              src={youtubeId}
              title='YouTube video player'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            />
          </div>
        )}
      </div>
    </section>
  )
}

export default Review

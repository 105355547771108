import { lazy } from 'react'

import { ERoutePaths, type TRoutePageType } from './types'

const Home = lazy(() => import('pages/Home'))
const Edition = lazy(() => import('pages/Edition'))
const Error = lazy(() => import('pages/Error'))

const routesList: TRoutePageType[] = [
  {
    element: Home,
    path: ERoutePaths.Home,
    title: 'Home',
  },
  {
    element: Edition,
    path: ERoutePaths.Edition,
    title: 'Home',
  },
  {
    element: Error,
    path: ERoutePaths.Error,
    title: 'Error',
  },
]

export default routesList

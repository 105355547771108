import { FC } from 'react'
import classNames from 'classnames'
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown'

import type { TTimerComponentProps } from './types'
import styles from './TimerComponent.module.scss'

const TimerComponent: FC<TTimerComponentProps> = ({ date, className = '' }) => {
  const TO = new Date(date).getTime()

  return (
    <div role='button' className={classNames(styles.wrapper, { [className]: className })}>
      <h2 className={styles.title}>Starting In</h2>
      <div className={styles.wrapper__content}>
        <FlipClockCountdown
          to={TO}
          className='flip-clock'
          digitBlockStyle={{ background: '#ff9900' }}
          dividerStyle={{ color: '#fff', height: 2 }}
          separatorStyle={{ color: '#ff9900', size: '6px' }}
        />
      </div>
    </div>
  )
}
export default TimerComponent

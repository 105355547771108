import type { FC } from 'react'

import styles from './Sponsors.module.scss'
import SponsorCard from './SponsorCard'

import { sponsorsList } from 'utils'

type SponsorsProps = {
  comingSoon?: boolean
  isEdition?: boolean
}

const Sponsors: FC<SponsorsProps> = ({ comingSoon = false, isEdition = false }) => {
  const renderSponsorsList = sponsorsList[isEdition ? '2024' : '2025'].map(data => (
    <SponsorCard key={data.id} data={data} isEdition />
  ))

  return (
    <section id='SPONSORS' className={styles.wrapper}>
      <div className='container'>
        <p className={styles.wrapper__title}>Meet our Sponsors</p>
        {comingSoon ? (
          <div className={styles.soon} />
        ) : (
          <div className={styles.wrapper__list__gaper}>{renderSponsorsList}</div>
        )}
      </div>
    </section>
  )
}

export default Sponsors

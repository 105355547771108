import { ReactComponent as NotificationIcon } from '../assets/svg/joinUs.svg'

const navigationList = [
  {
    id: 1,
    title: 'About Community Day',
    link: 'ABOUT-COMMUNITY-DAY',
  },

  // {
  //   id: 3,
  //   title: 'Speakers',
  //   link: 'SPEAKERS',
  // },

  // {
  //   title: 'Become a Speaker',
  //   link: 'become-a-speaker',
  // },

  {
    id: 2,
    title: 'Agenda',
    link: 'AGENDA',
  },

  {
    id: 5,
    title: 'Sponsors',
    link: 'SPONSORS',
  },

  {
    id: 4,
    title: 'Team',
    link: 'TEAM',
  },
  {
    id: 8,
    title: 'Past events',
    link: '/edition',
    isLink: true,
  },
  {
    id: 7,
    title: 'Join Us',
    link: 'FOOTER',
    Icon: NotificationIcon,
  },
]

export default navigationList

import { FC } from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import styles from './AboutUs.module.scss'

const AboutUs: FC = () => (
  <section className={styles.one}>
    <div className={styles.wrapper} id={'ABOUT-COMMUNITY-DAY'}>
      <div className={classNames(styles.one_content, 'container')}>
        <div className={styles.one_content__left}>
          <div className={styles.one_content__left_content}>
            <p className={styles.one_content__left_content_title}>WHO WE ARE?</p>
            <p className={styles.one_content__left_content_description}>
              <NavLink
                target='_blank'
                to='https://linktr.ee/awsugarmenia'
                className={styles.one_content__left_content_description_link}
              >
                AWS User Group Armenia
              </NavLink>{' '}
              is your gateway to cloud mastery! As the premier community for cloud enthusiasts in Armenia and beyond, we
              unite DevOps professionals, developers, all IT professionals and cloud technology users.
              <p className={styles.one_content__left_content_description}>
                Our mission? To empower you through collaborative learning, exclusive workshops, sharing news and
                articles, supporting ideas and direct interaction with industry leaders.
              </p>
              Join the community to dive into the world of cloud technologies and AWS and turn possibilities into
              achievements. Regardless your level, here will be something for you.
            </p>
          </div>
        </div>

        <div className={styles.one_content__right}>
          <p className={styles.one_content__right_title}>WHAT TO EXPECT FROM AWS COMMUNITY DAY?</p>
          <p className={styles.one_content__right_content_description}>
            AWS Community Days are community-organized cloud education events, featuring technical discussions and demos
            led by expert AWS local users, AWS Heroes, AWS Guest Speakers and industry leaders from around the world. In
            many ways this is an event put on "by the community, for the community."
          </p>
          <p className={styles.one_content__right_content_description}></p>
          <ul className={styles.one_content__right_list} style={{ marginTop: '20px' }}>
            <li className={styles.one_content__right_list_item}>Engage, get inspired and energize</li>
            <li className={styles.one_content__right_list_item}>Expert insights</li>
            <li className={styles.one_content__right_list_item}>Networking opportunities</li>
            <li className={styles.one_content__right_list_item}>Interactive sessions</li>
            <li className={styles.one_content__right_list_item}>Visibility and impact</li>
            <li className={styles.one_content__right_list_item}>Win Exclusive AWS Swags</li>
          </ul>
          <p className={styles.one_content__right__footer}>
            Please read the{' '}
            <a href='https://aws.amazon.com/codeofconduct/' target='_blank' rel='noopener noreferrer'>
              Code of Conduct
            </a>{' '}
            for this event.
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default AboutUs

import classNames from 'classnames'
import { Link, useNavigate } from 'react-router-dom'
import ScrollIntoView from 'react-scroll-into-view'

import { ERoutePaths } from 'libraries/router/types'

import styles from './Header.module.scss'

const NavItem = ({ title, Icon, link, index, isLink, setIsOpened }: any) => {
  const navigate = useNavigate()
  const isEdition = window.location.pathname === ERoutePaths.Edition

  return isLink ? (
    <Link className={styles.wrapper__content} to={isEdition ? ERoutePaths.Home : link}>
      <h3>{!isEdition ? title : 'Current Event'}</h3>
    </Link>
  ) : (
    <ScrollIntoView
      selector={`#${link}`}
      className={styles.item__child}
      onClick={() => {
        setIsOpened(false)

        if (isEdition) {
          navigate(ERoutePaths.Home)
        }
      }}
    >
      <span>
        <div
          className={classNames(styles.wrapper__content, {
            [styles.wrapper__content_active]: index === 0,
          })}
        >
          {title && <h3>{title}</h3>}

          {Icon && <Icon />}
        </div>
      </span>
    </ScrollIntoView>
  )
}

export default NavItem
